import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Form, Grid, Loader, Message, Segment } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import UserContext from './../../../context/user';
import NoticeContext from './../../../context/notice';
import { ApiUserLogin } from './../../../api/user';
import MessageContext from './../../message';
import Layout from './../../layout/Layout';


const UserLogin = (props) => {

    const [ form, setForm ] = useState({ email: '', password: '' });

    const [ onSubmit, setOnSubmit ] = useState(false);

    const { setNotice } = useContext(NoticeContext);

    const { user, setUser } = useContext(UserContext);

    const history = useHistory();

    const handleSubmit = event => {
        setOnSubmit(true);
        ApiUserLogin(form.email, form.password)
            .then(response => {
                setOnSubmit(false);
                setUser(response);
            })
            .catch(error => {
                setOnSubmit(false);
                setNotice(error);
			});
    }

    useEffect(() => {
        if (user !== null) {
            setNotice('Vous êtes maintenant connecté');
            history.push('/user/profile');
        }
    
    }, [ user ]);


    if (user !== null) {
        return false;
    }

    return (
        <div className="login-form">
            <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
                <Grid.Column style={{ maxWidth: 450 }}>
                    <MessageContext />
                    <Form size="large" onSubmit={ handleSubmit }>
                        <Segment stacked>
                            { onSubmit ? <Loader active /> : false }
                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                autoCorrect="off"
                                autoCapitalize="off"
                                placeholder="E-mail address"
                                value={form.email}
                                onChange={e => setForm({...form, email: e.target.value })}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                autoCorrect="off"
                                autoCapitalize="off"
                                placeholder="Password"
                                type="password"
                                value={form.password}
                                onChange={e => setForm({...form, password: e.target.value })}
                            />
                            <Button type="submit" color="teal" fluid size="large">
								Se connecter
							</Button>
                        </Segment>
                        <Message>
							<Link to="/user/register">Créer son compte</Link>
						</Message>
                    </Form>
                </Grid.Column>
            </Grid>
        </div>
    )
};

export default Layout(UserLogin);