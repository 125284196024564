export const getUserHighlightsByChapitre = async (user, version, book, chapitre) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BIBLE}/api/v2/user_highlight/${version}/${book}/${chapitre}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
            }
        }
    );

    return await response.json();
};

export default getUserHighlightsByChapitre;