import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';
import NoticeContext from './../context/notice';



const MessageContext = () => {

    const { notice, setNotice } = useContext(NoticeContext);

    if (!notice) {
        return false;
    }

    return (
        <Message 
            color="blue" 
            onDismiss={ e => setNotice(false)} 
            content={`${notice}`} 
        />
    )
}

export default MessageContext;