
import React, { createRef,  Fragment, useEffect } from 'react';
import _ from 'lodash';


import UserHighlight from './userHighlight';


const Canvas = ( props ) => { 

    const { height, userHighlights, width } = props; 

    const ref = createRef(null);

    if(height == null || width == null || !userHighlights ) {
        return false;
    }

    return (
        <Fragment>
            <svg 
                ref={ref} 
                id="versets_canvas" 
                xmlns="http://www.w3.org/2000/svg" 
                version="1.1"
                viewBox={`0 0 ${width} ${height}`} 
            >
                { userHighlights.map( (t, i) => (
                    <UserHighlight 
                        key={i} 
                        {...t} 
                        parent={ref} 
                    />
                ))}
            </svg>
        </Fragment>
    )
}

export default Canvas;