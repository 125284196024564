import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import BibleCollection from './../../../models/BibleLivreCollection';
import Layout from './../../layout/Layout';
import './Livres.css';

const bible = new BibleCollection();

const LivreItem = ({ livre, version }) => (
	<Grid.Column>
		<Link to={`/version/${version}/livre/${livre.key}`} className="list-group-item">
			{livre.title.substr(0, 4)}
		</Link>
	</Grid.Column>
);

const Livres = (props) => {

	const version = props.match.params.version; 

	const livres = bible.livres().map(livre => {
		return <LivreItem key={livre.key} version={version} livre={livre} />;
	});

	return (
		<Container text>
			<Header as="h3">Livres</Header>
			<Grid columns={4} padded>
				{livres}
			</Grid>
		</Container>
	);
}

export default Layout(Livres);
