import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Redirect, Router, Route } from 'react-router-dom';

import BibleVersionsContext from './context/versions';
import BookmarksContext from './context/bookmarks';
import UserContext from './context/user';
import NoticeContext from './context/notice';
import ReadingContext from './context/reading';

import { Chapitre, Livre, Livres, Versions } from './components/pages';
import UserBookmarks from './components/pages/user/bookmarks';
import UserLogin from './components/pages/user/login';
import UserProfile from './components/pages/user/profile';
import UserRegister from './components/pages/user/register';
import { localStore } from './util';

const history = createBrowserHistory();

const App = () => {

	const [ bookmarks, setBookmarks ] = useState(null);

	const [ user, setUser ] = useState(
		Object.keys(localStore('user_account')).length > 1 ? 
			localStore('user_account') : 
			null
	);

	const [ notice, setNotice ] = useState(null);

	const [ reading, setReading ] = useState(
		Object.keys(localStore('user_reading')).length > 1 ? 
		localStore('user_reading') : {
			version: 'neg79',
			book: 'Gen',
			chapter: 1
		}
	);

	const [ versions, setVersions ] = useState({}); 

	const setGlobalUser = user => {
		setUser(user);
		localStore('user_account', user);
	};

	return (
		<UserContext.Provider value={{ user, setUser: setGlobalUser }}>
			<NoticeContext.Provider value={{ notice, setNotice }}>
				<BibleVersionsContext.Provider value={{ versions, setVersions }}>
					<BookmarksContext.Provider value={{ bookmarks, setBookmarks }}>
						<ReadingContext.Provider value={{ reading, setReading }} >
							<Router history={history}>
							
								<div>
									{/** USER */}
									
									<Route path="/user/login" exact component={UserLogin} />
									<Route path="/user/profile" exact component={UserProfile} />
									<Route path="/user/register" exact component={UserRegister} />

									{/** PROFILE */}
									<Route path="/profile/bookmarks" exact component={UserBookmarks} />
									
									{/** BIBLE */}
									<Route path="/version/:version/livre/:livre/chapitre/:chapitre" exact component={Chapitre} />
									<Route path="/version/:version/livre/:livre" exact component={Livre} />
									<Route path="/version/:version" exact component={Livres} />
									<Route path="/version" exact component={Versions} />
									<Route 
										path="/" 
										exact 
										render={() => (<Redirect to={`/version/${reading.version}/livre/${reading.book}/chapitre/${reading.chapter}`} />)}
									/>
								</div>
							
							</Router>
						</ReadingContext.Provider>
					</BookmarksContext.Provider>
				</BibleVersionsContext.Provider >
			</NoticeContext.Provider >
		</UserContext.Provider>
	)
};

export default App;