export const getApiBibleVersions = async (signal) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_BIBLE}/api/v2/bible/versions`
	);
	return await response.json();
}

export const getLivre = async (version, book) => {

	const response = await fetch(
		`${process.env.REACT_APP_API_BIBLE}/api/v2/bible/version/${version}/book/${book}`
	);
	return await response.json();
}

export const getChapitre = async (version, book, chapter) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_BIBLE}/api/v2/bible/version/${version}/book/${book}/chapter/${chapter}`
	);
	return await response.json();
}


export default getApiBibleVersions;