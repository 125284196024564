import React, { createRef, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import _, { filter, map } from 'lodash';
import { Link } from 'react-router-dom';
import { Container, Grid, Segment } from 'semantic-ui-react';
import RangeIterator from 'range-iterator';

import Layout from './../../layout/Layout';
import BibleCollection from './../../../models/BibleLivreCollection';
import { getChapitre, getLivre } from './../../../api/bible';
import { getUserHighlightsByChapitre } from './../../../api/userHighlight'
import UserContext from './../../../context/user';
import { localStore } from './../../../util';
import Canvas from './canvas';
import './chapitre.css';

const bible = new BibleCollection(); 

const Verset = (props) => {

	const { book, chapter, displayVerse, text, verse } = props; 
	
	return (
		<div className="verset_inner" >
			<div className={`number`} >{displayVerse}</div>
			<div className="verset">
				{text.split('').map((letter, index) => {
					return (
						<Fragment key={index}>
							<span 
								id={`${book}_${chapter}_${verse}_${index}`} 
								className="bible_letter" 
							>{letter}</span>
						</Fragment>
					);
				})}
			</div>
		</div>
	)

};

const Chapitre = (props) => {

	const { version, livre, chapitre } = props.match.params;

	const [ versets, setVersets ] = useState([]); 
	const [ info, setInfo ] = useState(null); 
	const [ userHighlights, setUserHighlights ] = useState([]); 
	const [ width, setWidth ] = useState(null);
	const [ height, setHeight ] = useState(null); 
	const { user } = useContext(UserContext);  
	const ref =  createRef(null); 

	useEffect( () => {
		localStore('user_reading', {
			version,
			book: livre,
			chapter: chapitre
		})

		getUserHighlightsByChapitre(user, version, livre, chapitre).then(response => {
			setUserHighlights(response);
		})
		
	}, [] );

	useEffect( () => {

		if(ref.current && width != ref.current.offsetWidth) {
            setWidth(ref.current.offsetWidth);
		}
		
        if(ref.current && height != ref.current.offsetHeight) {
            setHeight(ref.current.offsetHeight);
		}

	}, [versets]);

	useEffect( () => {

		let tmp = filter( userHighlights, uh => uh.highlight.color != null && uh.highlight.color.font == 'inverse' );

		map(tmp, uh => {
            let range = new Range();

            let startElt = document.getElementById(
                `${uh.start.livre}_${uh.start.chapitre}_${uh.start.numero}_${uh.start.decalage}`
            );

            let endElt = document.getElementById(
                `${uh.end.livre}_${uh.end.chapitre}_${uh.end.numero}_${uh.end.decalage}`
            );

            range.setStartBefore(startElt, 0);
            range.setEndAfter(endElt, endElt.childNodes.length);

            let iterator = RangeIterator(range, NodeFilter.SHOW_ALL, function(node, index) {
                return (
                    node.nodeName === 'SPAN' &&
                    node.className === 'bible_letter'
                );
            });

            startElt.style.color = 'white';

            for (let node of iterator) {
                node.style.color = 'white';
            }
        })

	}, [versets, userHighlights]);




	useEffect(() => {
		setVersets([]);
		Promise.all([
			getChapitre(version, livre, chapitre), 
			getLivre(version, livre)
		]).then( values => {
			setVersets(values[0]);
			setInfo(values[1]);
		});

	}, [version, livre, chapitre]);

	const getVersets = useCallback(() => {

		if(!versets.length) {
			return false;
		}
		
		return versets.map( (v, i) => <Verset key={i} {...v} version={version} />);

	}, [ versets, userHighlights ]);

	const getChapitrePrev = useCallback(() => {

		if (chapitre > 1) {
			return (
				<Link to={`/version/${version}/livre/${livre}/chapitre/${chapitre - 1}`} className="mini ui icon button">
					<i className="angle left icon" />
				</Link>
			);
		}

		return false;

	}, [ chapitre ]);

	const getChapitreNext = useCallback(() => {

		if ( info && chapitre < info.chapters ) {
			return (
				<Link 
					to={`/version/${version}/livre/${livre}/chapitre/${ parseInt(chapitre) + 1}`} 
					className="mini ui icon button"
				>
					<i className="angle right icon" />
				</Link>
			);
		}

		return false;

	}, [ chapitre, info ]);

	const renderLivrePrev = useCallback( () => {
		
		if ( livre != 'Gen' ) {
			return (
				<Link 
					to={`/version/${version}/livre/${bible.prev(livre)}/chapitre/1`} 
					className="mini ui icon button" 
				>
					<i className="angle double left icon" />
				</Link>
			);
		}

		return false;

	}, [ livre ]);


	const renderLivreNext = useCallback( () => {
		
		if ( livre != 'Rev' ) {
			return (
				<Link 
					to={`/version/${version}/livre/${bible.next(livre)}/chapitre/1`} 
					className="mini ui icon button" 
				>
					<i className="angle double right icon" />
				</Link>
			);
		}

		return false;

	}, [ livre ]);


	return (
		<React.Fragment>
			<Container text>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Segment textAlign="center">
								{ renderLivrePrev() }
								{ getChapitrePrev() }
								<span className="title">
									<strong>
									{bible.getLivreByKey(livre).title} {chapitre}
									</strong>
								</span>
								{ getChapitreNext() }
								{ renderLivreNext() }
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid>
					<Grid.Row>
						<Grid.Column >
							<div ref={ref} className="versets_wrapper">
								{ getVersets() } 
								<Canvas 
									height={height}
									width={width}
									userHighlights={userHighlights} 
									version={version}
								/>
							</div>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

export default Layout(Chapitre);