import React, { useEffect, useState, useCallback }  from 'react';
import { range } from 'lodash';
import { Container, Grid, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getLivre } from './../../../api/bible';
import Layout from './../../layout/Layout';

import './Livre.css';

const LivreItem = ({ livre, chapitre, version }) => (
	<Grid.Column>
		<Link 
			to={`/version/${version}/livre/${livre}/chapitre/${chapitre}`} 
			className="chapitre-item"
		>{ chapitre }</Link>
	</Grid.Column>
);

const Livre = props => {

	const { version, livre } = props.match.params;
	const [ info, setInfo ] = useState(null);

	useEffect(() => {
        getLivre(version, livre).then(response => {
			setInfo(response);
        });
	}, []);
	
	const items = useCallback(() => {

		if(!info) {
			return false;
		}

		return range(1, info.chapters ).map( (c, i) => {
			return (
				<LivreItem key={i} version={version} livre={livre} chapitre={c} />
			)
		});
	}, [ info ]);

	if(!info) {
		return false;
	}
	
	return (
		<Container text>
			<Header as="h3">{info.title}</Header>
			<Grid columns={4} padded>{items()}</Grid>
		</Container>
	);
}

export default Layout(Livre);
