import React, {Fragment} from 'react';
import { Container, Icon, Menu } from 'semantic-ui-react';

const MenuTop = ({ onShowSideBar }) => {
	return (
		<Fragment>
			<Menu fixed="top" inverted>
				<Container>
					<Menu.Item as="a" header onClick={onShowSideBar}>
						<Icon name="content" />
					</Menu.Item>
				</Container>
			</Menu>
		</Fragment>
	)
}

export default MenuTop;
