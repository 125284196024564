import React, { useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Layout from './../../layout/Layout';
import { Container, Grid, List } from 'semantic-ui-react';

import BookmarksContext from './../../../context/bookmarks';
import NoticeContext from './../../../context/notice';
import UserContext from './../../../context/user';
import { ApiGetBookmarks } from './../../../api/bookmark';

const BookMark = (props) => {

    const { chapitre, livreId, livreNom } = props;

    return (
        <List.Item >
            <Link 
                to={`/version/neg79/livre/${livreId}/chapitre/${chapitre}`} 
                className="list-group-item"
            >
                {livreNom} {chapitre}
            </Link>
        </List.Item>
    )
}

const UserBookmarks = () => { 

    const { bookmarks, setBookmarks } = useContext(BookmarksContext);

    const { setNotice } = useContext(NoticeContext);

    const { user } = useContext(UserContext);  

    const history = useHistory();

    useEffect(() => {

        if (user !== null && !bookmarks) {
            ApiGetBookmarks(user)
                .then(response => { 
                    setBookmarks(response);
                })
                .catch(error => {
                    setNotice(error);
                });
        }
        
    }, [ user, bookmarks ]);

    useEffect(() => {

        if (user === null) {
            setNotice('Vous devez vous connecter');
            history.push('/user/login');
        }
    
    }, [ user ]);

    if (user === null) {
        return false;
    }

    return (
        <Container text>
            <Grid>
                <Grid.Column>{ bookmarks ? _.map(
                    bookmarks, 
                    ( b,i ) => <BookMark key={i} {...b} />)
                : false }</Grid.Column>
            </Grid>
        </Container>
    )
}

export default Layout(UserBookmarks);