import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Container, Header, List, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Layout from './../../layout/Layout';
import { getApiBibleVersions } from './../../../api/bible';
import MessageContext from './../../message';
import BibleVersionsContext from './../../../context/versions';

const Item = ({ title, version }) => { 
    return (
        <List.Item >
            <Link 
                to={`/version/${version}`} 
                className="list-group-item" 
            >{title}</Link>
        </List.Item>
    )
};

const Versions = () => {

    const { versions, setVersions } = useContext(BibleVersionsContext);

    useEffect(() => {
        
        
        
        if(_.isEmpty(versions)) {

            const controller = new AbortController();
            
            const api = getApiBibleVersions(controller.signal);
            
            api.then(response => {
                setVersions(response);
            });

            return () => {
                controller.abort();
            }
        }

        return () => {
            
        };

    }, [ ]);



    return (
        <Container text>
            <MessageContext />
            <Header as="h3" >Versions</Header>
            <div className="livres-list">
                {_.map( versions, (v,i) => <Item key={i} version={i} title={v}/>)}
            </div>
        </Container>
    )
};

export default Layout(Versions);