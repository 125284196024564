import React from 'react';
import MessageContext from './../../message';
import Layout from './../../layout/Layout';

const UserProfile = (props) => {
    return (
        <div>
            <MessageContext />
        </div>
    );
}

export default Layout(UserProfile);