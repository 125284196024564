import React, { Fragment, useContext, useEffect } from 'react';

import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import UserContext from './../../context/user';

import MenuTop from './MenuTop';

const UserLink = () => {
	const { user } = useContext(UserContext); 

	if(!user) {
		return false;
	}

	return (
		<Fragment>
			<Menu.Item as={Link} to="/user/profile">
				Mon compte
			</Menu.Item>
			<Menu.Item as={Link} to="/profile/bookmarks">
				Marque-pages
			</Menu.Item>
		</Fragment>
	);
};

const AnonymousLink = () => {
	const { user } = useContext(UserContext); 

	if(user) {
		return false;
	}

	return (
		<Fragment>
			<Menu.Item as={Link} to="/user/login">
				Se connecter
			</Menu.Item>
			
			<Menu.Item as={Link} to="/user/register">
				Créer son compte
			</Menu.Item>
		</Fragment>
	);
};

const MySideBar = (props) => { 
	const { children, onHideSideBar, onShowSideBar, visible } = props; 
	return (
		<React.Fragment>
			<Sidebar
				as={Menu}
				animation="overlay"
				direction="left"
				vertical
				icon="labeled"
				inverted
				width="thin"
				onHide={onHideSideBar}
				visible={visible}
				/* className="fixed"*/
			>
				<Menu.Item as={Link} to="/version">
					Accueil
				</Menu.Item>
				<UserLink />
				<AnonymousLink />
			</Sidebar>
			<Sidebar.Pushable as={Segment} />
			<MenuTop onShowSideBar={onShowSideBar} />
			<Sidebar.Pusher>
				<Segment basic>{ children }</Segment>
			</Sidebar.Pusher>
		</React.Fragment>
	);
};


export default MySideBar;
