import React, { Fragment, useState } from 'react';

import SideBar from './SideBar';

import './Layout.css';

const Layout = (BaseComponent) => {  
	
	const App = props => {

		const [ visible, setVisible ] = useState(false);

		const handleShowClick = e => {
			setVisible(true);
		}
	
		const handleHideClick = e => {
			setVisible(false);
		}

		return (
			<Fragment>
				<SideBar
					visible={visible}
					onHideSideBar={handleHideClick}
					onShowSideBar={handleShowClick}
				>
					<BaseComponent { ...props }/>
				</SideBar>
			</Fragment>
		)

	}
	
	return App;
}

export default Layout;
