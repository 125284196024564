import axios from 'axios';

export const ApiUserLogin = async (email, password) =>  {
    
    const response = await axios.post(
        `${ process.env.REACT_APP_API_BIBLE }/api/user/login`,
        JSON.stringify({ email, password }), 
        {
            'Content-Type': 'application/json',
            crossdomain: true 
        }
    )
    .then( (success) => { return success.data; })
    .catch( (error) => { 
        throw new Error(error.response.data.message);
    })
    
    return await response;
};

export const ApiUserRegister = async (email, password) =>  {
    const response = await axios.post(
        `${ process.env.REACT_APP_API_BIBLE }/api/user/register`,
        JSON.stringify({ email, password }),
        {
            'Content-Type': 'application/json',
            crossdomain: true 
        }
    )
    .then( (success) => { return success.data; })
    .catch( (error) => { 
        throw new Error(error.response.data.message);
    });
    
    return await response;
};



export default ApiUserLogin;