/* bible */
import Livres from './livres/';
import Livre from './livre/';
import Chapitre from './chapitre/';
import Versions from './versions/';



export {
	Chapitre,
	Livre,
	Livres,
	Versions
};
