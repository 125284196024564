import { find, filter, mapKeys } from 'lodash';

export default class BibleLivreCollection {
	constructor() {
		this.items = [
			{ index: 1, key: 'Gen', title: 'Gen\u00e8se', chapters: 50, categorie: 'Le Pentateuque' },
			{ index: 2, key: 'Exod', title: 'Exode', chapters: 40, categorie: 'Le Pentateuque' },
			{ index: 3, key: 'Lev', title: 'L\u00e9vitique', chapters: 27, categorie: 'Le Pentateuque' },
			{ index: 4, key: 'Num', title: 'Nombres', chapters: 36, categorie: 'Le Pentateuque' },
			{ index: 5, key: 'Deut', title: 'Deut\u00e9ronome', chapters: 34, categorie: 'Le Pentateuque' },
			{ index: 6, key: 'Josh', title: 'Josu\u00e9', chapters: 24, categorie: 'Livres historiques' },
			{ index: 7, key: 'Judg', title: 'Juges', chapters: 21, categorie: 'Livres historiques' },
			{ index: 8, key: 'Ruth', title: 'Ruth', chapters: 4, categorie: 'Livres historiques' },
			{ index: 9, key: '1Sam', title: '1 Samuel', chapters: 31, categorie: 'Livres historiques' },
			{ index: 10, key: '2Sam', title: '2 Samuel', chapters: 24, categorie: 'Livres historiques' },
			{ index: 11, key: '1Kgs', title: '1 Rois', chapters: 22, categorie: 'Livres historiques' },
			{ index: 12, key: '2Kgs', title: '2 Rois', chapters: 25, categorie: 'Livres historiques' },
			{ index: 13, key: '1Chr', title: '1 Chroniques', chapters: 29, categorie: 'Livres historiques' },
			{ index: 14, key: '2Chr', title: '2 Chroniques', chapters: 36, categorie: 'Livres historiques' },
			{ index: 15, key: 'Ezra', title: 'Esdras', chapters: 10, categorie: 'Livres historiques' },
			{ index: 16, key: 'Neh', title: 'N\u00e9h\u00e9mie', chapters: 13, categorie: 'Livres historiques' },
			{ index: 17, key: 'Esth', title: 'Esther', chapters: 10, categorie: 'Livres historiques' },
			{ index: 18, key: 'Job', title: 'Job', chapters: 42, categorie: 'Livres po\u00e9tiques' },
			{ index: 19, key: 'Ps', title: 'Psaumes', chapters: 150, categorie: 'Livres po\u00e9tiques' },
			{ index: 20, key: 'Prov', title: 'Proverbes', chapters: 31, categorie: 'Livres po\u00e9tiques' },
			{ index: 21, key: 'Eccl', title: 'Eccl\u00e9siaste', chapters: 12, categorie: 'Livres po\u00e9tiques' },
			{ index: 22, key: 'Song', title: 'Cantique', chapters: 8, categorie: 'Livres po\u00e9tiques' },
			{ index: 23, key: 'Isa', title: 'Esa\u00efe', chapters: 66, categorie: 'Les Proph\u00e8tes' },
			{ index: 24, key: 'Jer', title: 'J\u00e9r\u00e9mie', chapters: 52, categorie: 'Les Proph\u00e8tes' },
			{ index: 25, key: 'Lam', title: 'Lamentations', chapters: 5, categorie: 'Les Proph\u00e8tes' },
			{ index: 26, key: 'Ezek', title: 'Ez\u00e9chiel', chapters: 48, categorie: 'Les Proph\u00e8tes' },
			{ index: 27, key: 'Dan', title: 'Daniel', chapters: 12, categorie: 'Les Proph\u00e8tes' },
			{ index: 28, key: 'Hos', title: 'Os\u00e9e', chapters: 14, categorie: 'Les Proph\u00e8tes' },
			{ index: 29, key: 'Joel', title: 'Jo\u00ebl', chapters: 4, categorie: 'Les Proph\u00e8tes' },
			{ index: 30, key: 'Amos', title: 'Amos', chapters: 9, categorie: 'Les Proph\u00e8tes' },
			{ index: 31, key: 'Obad', title: 'Abdias', chapters: 1, categorie: 'Les Proph\u00e8tes' },
			{ index: 32, key: 'Jonah', title: 'Jonas', chapters: 4, categorie: 'Les Proph\u00e8tes' },
			{ index: 33, key: 'Mic', title: 'Mich\u00e9e', chapters: 7, categorie: 'Les Proph\u00e8tes' },
			{ index: 34, key: 'Nah', title: 'Nahum', chapters: 3, categorie: 'Les Proph\u00e8tes' },
			{ index: 35, key: 'Hab', title: 'Habacuc', chapters: 3, categorie: 'Les Proph\u00e8tes' },
			{ index: 36, key: 'Zeph', title: 'Sophonie', chapters: 3, categorie: 'Les Proph\u00e8tes' },
			{ index: 37, key: 'Hag', title: 'Agg\u00e9e', chapters: 2, categorie: 'Les Proph\u00e8tes' },
			{ index: 38, key: 'Zech', title: 'Zacharie', chapters: 14, categorie: 'Les Proph\u00e8tes' },
			{ index: 39, key: 'Mal', title: 'Malachie', chapters: 3, categorie: 'Les Proph\u00e8tes' },
			{ index: 40, key: 'Matt', title: 'Matthieu', chapters: 28, categorie: 'Les Evangiles' },
			{ index: 41, key: 'Mark', title: 'Marc', chapters: 16, categorie: 'Les Evangiles' },
			{ index: 42, key: 'Luke', title: 'Luc', chapters: 24, categorie: 'Les Evangiles' },
			{ index: 43, key: 'John', title: 'Jean', chapters: 21, categorie: 'Les Evangiles' },
			{ index: 44, key: 'Acts', title: 'Actes', chapters: 28, categorie: 'Les \u00e9pitres' },
			{ index: 45, key: 'Rom', title: 'Romains', chapters: 16, categorie: 'Les \u00e9pitres' },
			{ index: 46, key: '1Cor', title: '1 Corinthiens', chapters: 16, categorie: 'Les \u00e9pitres' },
			{ index: 47, key: '2Cor', title: '2 Corinthiens', chapters: 13, categorie: 'Les \u00e9pitres' },
			{ index: 48, key: 'Gal', title: 'Galates', chapters: 6, categorie: 'Les \u00e9pitres' },
			{ index: 49, key: 'Eph', title: 'Eph\u00e9siens', chapters: 6, categorie: 'Les \u00e9pitres' },
			{ index: 50, key: 'Phil', title: 'Philippiens', chapters: 4, categorie: 'Les \u00e9pitres' },
			{ index: 51, key: 'Col', title: 'Colossiens', chapters: 4, categorie: 'Les \u00e9pitres' },
			{ index: 52, key: '1Thess', title: '1 Thessaloniciens', chapters: 5, categorie: 'Les \u00e9pitres' },
			{ index: 53, key: '2Thess', title: '2 Thessaloniciens', chapters: 3, categorie: 'Les \u00e9pitres' },
			{ index: 54, key: '1Tim', title: '1 Timoth\u00e9e', chapters: 6, categorie: 'Les \u00e9pitres' },
			{ index: 55, key: '2Tim', title: '2 Timoth\u00e9e', chapters: 4, categorie: 'Les \u00e9pitres' },
			{ index: 56, key: 'Titus', title: 'Tite', chapters: 3, categorie: 'Les \u00e9pitres' },
			{ index: 57, key: 'Phlm', title: 'Phil\u00e9mon', chapters: 1, categorie: 'Les \u00e9pitres' },
			{ index: 58, key: 'Heb', title: 'H\u00e9breux', chapters: 13, categorie: 'Les \u00e9pitres' },
			{ index: 59, key: 'Jas', title: 'Jacques', chapters: 5, categorie: 'Les \u00e9pitres' },
			{ index: 60, key: '1Pet', title: '1 Pierre', chapters: 5, categorie: 'Les \u00e9pitres' },
			{ index: 61, key: '2Pet', title: '2 Pierre', chapters: 3, categorie: 'Les \u00e9pitres' },
			{ index: 62, key: '1John', title: '1 Jean', chapters: 5, categorie: 'Les \u00e9pitres' },
			{ index: 63, key: '2John', title: '2 Jean', chapters: 1, categorie: 'Les \u00e9pitres' },
			{ index: 64, key: '3John', title: '3 Jean', chapters: 1, categorie: 'Les \u00e9pitres' },
			{ index: 65, key: 'Jude', title: 'Jude', chapters: 1, categorie: 'Les \u00e9pitres' },
			{ index: 66, key: 'Rev', title: 'Apocalypse', chapters: 22, categorie: 'Les \u00e9pitres' },
		];

		this.categories = [
			'Le Pentateuque',
			'Livres historiques',
			'Livres po\u00e9tiques',
			'Les Proph\u00e8tes',
			'Les Evangiles',
			'Les \u00e9pitres',
		];
	}

	getLivreByKey(key) {
		return find(this.items, function(l) {
			return l.key === key;
		});
	}

	getNextLivreByKey(key) {
		let livre = this.getLivreByKey(key);
		if (livre) {
			return this.items[livre.index].key;
		}
		return null;
	}

	prev(key) {
		let livres = mapKeys(this.items, 'index');
		let livre = this.getLivreByKey(key);
		if (livre.index > 1) {
			return livres[livre.index - 1].key;
		}
		return null;
	}

	next(key) {
		let livres = mapKeys(this.items, 'index');
		let livre = this.getLivreByKey(key);

		if (livre.index < 66) {
			return livres[livre.index + 1].key;
		}
		return null;
	}

	livres() {
		return this.items;
	}

	getCategories() {
		return this.categories;
	}

	getLivresByCategorie(categorie) {
		return filter(this.items, function(l) {
			return l.categorie === categorie;
		});
	}
}
