import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Form, Icon, Grid, Segment } from 'semantic-ui-react';
import MessageContext from './../../message';
import NoticeContext from './../../../context/notice';
import UserContext from './../../../context/user';
import Layout from './../../layout/Layout';
import { ApiUserRegister } from './../../../api/user';

const RegisterForm = (props) => {

    const [ form, setForm ] = useState({ email: '', password: '' });

    const [ errors, setErrors ] = useState(null);

    const { setNotice } = useContext(NoticeContext);

    const { user, setUser } = useContext(UserContext); 

    const history = useHistory();

    const handleSubmit = event => {
        let tmp = validate(form);
        setErrors(tmp);
        if( _.isEmpty(tmp)) {
            ApiUserRegister(form.email, form.password)
            .then(response => {
                setUser(response);
            })
            .catch(error => {
                setNotice(error);
			});
        }
    }

    const validate = values => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Vous devez entrer un email';
        }

        if (!values.password) {
            errors.password = 'Vous devez entrer un mot de passe';
        }

        return errors;
    }

    useEffect(() => {
        if (user !== null) {
            setNotice('Votre compte a été enregistré. Consulter votre messagerie pour confirmer votre email');
            history.push('/user/profile');
        }
    
    }, [ user ]);


    if (user !== null) {
        return false;
    }

    return (
        <Form size="large" onSubmit={ handleSubmit } >
            <Segment stacked>
                <Form.Input
                    fluid
                    iconPosition="left"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="E-mail address"
                    value={form.email}
                    error={errors && errors.email ? {content: errors.email }: false }
                    onChange={e => setForm({...form, email: e.target.value })}
                >
                    <input type="email"/>
                    <Icon name='user' />
                </Form.Input>
                <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="Password"
                    type="password"
                    value={form.password} 
                    error={errors && errors.password ? { content: errors.password }: false }
                    onChange={e => setForm({...form, password: e.target.value })}
                />
                <Button 
                    type="submit" 
                    color="teal" 
                    fluid 
                    size="large" 
                >Créer son compte</Button>
            </Segment>
        </Form>
    );
};

const UserRegister = () => {
    return (
        <Grid 
            textAlign="center" 
            style={{ height: '100%' }} 
            verticalAlign="middle"
        >
            <Grid.Column style={{ maxWidth: 450 }}>
                <div>
                    <MessageContext />
                    <RegisterForm />
                </div>
            </Grid.Column>
        </Grid>
    )
}

export default Layout( UserRegister );