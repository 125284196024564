import React, { Fragment, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { dasharray, fillByLine } from './onselect';

const Highlight = (props) => {

    const { highlight, coordinates } = props;
    
    if(!coordinates || !highlight.color) {
        return null;
    }

    return (
        <Fragment>
            { coordinates.map(
                (c, i) => (
                    <rect 
                        key={i}
                        x={ c.x }
                        y={ c.y }
                        width={ c.width }
                        height={ c.height }
                        fill={ highlight.color.color }
                    />
                )
            ) }
        </Fragment>
    )
}

const Underline = (props) => {
    const { underline, coordinates } = props;

    if (!coordinates || (!underline.color && !underline.line) ) {
        return false;
    }

    const getColor = useCallback( () => {
        let color  = 'black';
        
        if( underline.line && underline.line.color ) {
            color =  underline.line.color;
        }

        if( underline.color && underline.color ) {
            color = underline.color.color;
        }
        
        return color;
    })

    const getDashArray = useCallback( () => {

        if( underline.line && underline.line.type ) {
            return dasharray[underline.line.type]
        }

        return '';
    })
    
    return (
        <Fragment>
            { coordinates.map(
                (c, i) => (
                    <line 
                        key={i}
                        x1={ c.x }
                        y1={ c.y + c.height + 2 }
                        x2={ c.x + c.width }
                        y2={ c.y + c.height + 2 }
                        stroke={ getColor() }
                        strokeDasharray={ getDashArray() }
                    />
                )
            ) }
        </Fragment>
    )
}

const Vertical = (props) => {
    const { vertical, coordinates } = props;

    if (!coordinates || ( !vertical.color && !vertical.line ) ) {
        return false;
    }

    const getColor = useCallback( () => {

        if( vertical.color && vertical.color.color ) {
            return vertical.color.color;
        }

        if( vertical.line && vertical.line.color ) {
            return vertical.line.color;
        }
        return 'black';
    })

    const getDashArray = useCallback( () => {

        if( vertical.line && vertical.line.type ) {
            return dasharray[vertical.line.type]
        }

        return '';
    })

    const getX = useCallback( c => {

        return coordinates.length > 1 ? 6 : c.x - 1;

    });

    return (
        <Fragment>
            { coordinates.map(
                (c, i) => (
                    <line 
                        key={i}
                        x1={ getX(c) }
                        y1={ c.y }
                        x2={ getX(c) }
                        y2={ c.y + c.height }
                        strokeWidth="3"
                        stroke={ getColor() }
                        strokeDasharray={ getDashArray() }
                    />
                )
            ) }
        </Fragment>
    )

}

const UserHighlight = (props) => {
    
    const {  end, highlight, parent, start, underline, vertical } = props;

    const [ coordinates, setCoordinates ] = useState(null)

    

    useEffect(() => {

        if( props.parent ) {
            
            let startId = `${start.livre}_${start.chapitre}_${start.numero}_${start.decalage}`;
            let endId = `${end.livre}_${end.chapitre}_${end.numero}_${end.decalage}`
            
            const startElt = document.getElementById(startId);
            const endElt = document.getElementById(endId);

            if (startElt != null && endElt != null) {
                let _coordinates = fillByLine(
                    parent.current, 
                    `${start.livre}_${start.chapitre}_${start.numero}_${start.decalage}`, 
                    `${end.livre}_${end.chapitre}_${end.numero}_${end.decalage}`
                )
                setCoordinates(_coordinates)
            } else {
                setCoordinates(null)
            }
        }  
        
    }, [ props.parent ]);

    return (
        <Fragment>
            <Highlight highlight={highlight} coordinates={coordinates} />
            <Underline underline={underline} coordinates={coordinates} />
            <Vertical vertical={vertical} coordinates={coordinates} />
        </Fragment>
    );
}

export default UserHighlight;