import offset from 'document-offset';
import RangeIterator from 'range-iterator';
import uuid from 'uuid/v4';
import $ from 'jquery';

const dasharray = { 
    continu: '' ,
    dashed: '9 3',
    dotted: '2 2',
    alterned: '8 2 2 2',
    morse: '8 2 2 2 2 2'
};

const fillByLine = (wrapper, start, end) => {
    let ids = getNodeByRange(setRangeByIds(start, end));
    let id;
    let coords = [];
    for (id in ids) {
        let row = ids[id];
        
        let coord = getCoordonneesByIds(wrapper, row[0], row[row.length - 1]);
        coords.push(coord);
    }
    return coords;
};

const setRangeByIds = (startId, endId) => {
    let range = document.createRange();
    range.setStart( document.getElementById(startId), 0);
    range.setEnd( 
        document.getElementById(endId), 
        document.getElementById(endId).childNodes.length
    );
    return range;
};

const getNodeByRange = range => {
    let iterator = RangeIterator(range, NodeFilter.SHOW_ALL, function(node, index) {
        return (
            node.nodeName === 'SPAN' &&
            (node.className === 'bible_letter' ||
                node.className === 'bible_letter lightfont' ||
                node.className === 'number')
        );
    });
    let ids = {};
    // ajout premier node qui n'est pas compris dans l'itérateur
    let id = $(range.startContainer).attr('id');
    let position = offset(range.startContainer);
    ids[position.top] = [];
    ids[position.top].push(id);

    for (let node of iterator) {
        id = $(node).attr('id');

        position = offset(node);
        if (!ids.hasOwnProperty(position.top)) {
            ids[position.top] = [];
        }
        ids[position.top].push(id);
    }
    return ids;
};

const getCoordonneesByIds = (wrapper, startId, endId) => {
    let container = offset(wrapper);
    let startElt = document.getElementById(startId);
   
    let endElt = document.getElementById( endId );

    if (startElt == null || endElt == null) {
        return false;
    }
    let start = offset(startElt);

    let end = offset(endElt);
    let coord = {};
    
    coord['x'] = start.left - container.left;
    coord['y'] = start.top - container.top;
    coord['width'] = end.left + endElt.offsetWidth - coord['x'] - container.left;
    coord['height'] = end.top + endElt.offsetHeight - coord['y'] - container.top;
    return coord;
};

const createNewUserHighlight = (userId, start, end) => {
    return {
        uuid: uuid(),
        user: userId,
        start: {
            version: start.version,
            livre: start.book,
            chapitre: start.chapter,
            numero: start.verse,
            decalage: start.shift,
        }, 
        end: {
            version: end.version,
            livre: end.book,
            chapitre: end.chapter,
            numero: end.verse,
            decalage: end.shift
        },
        highlight: {
            color: null
        },
        underline: {
            line: null,
            color: null
        },
        vertical: {
            line: null,
            color: null
        }
    };
}

const findHighlight = (userHighlights, start, end) => {
    return _.find(userHighlights, (uh) => {  
     
        return uh.start.version == start.version && 
            uh.start.livre == start.book && 
            uh.start.chapitre == start.chapter &&
            uh.start.numero == start.verse && 
            uh.start.decalage == start.shift && 
            uh.end.version == end.version && 
            uh.end.livre == end.book && 
            uh.end.chapitre == end.chapter &&
            uh.end.numero == end.verse && 
            uh.end.decalage == end.shift;
    });
}

const createNewUserTheme = (userId, start) => {
    
    return {
        uuid: uuid(),
        userId: userId,
        start: {
            version: start.version,
            livre: start.book,
            chapitre: start.chapter,
            numero: start.verse,
            decalage: start.shift,
        },
        ligne: null,
        theme: null
    }
}

const findUserTheme = (userThemes, start) => {
    return _.find(userThemes, (uh) => {  
     
        return uh.start.version == start.version && 
            uh.start.livre == start.book && 
            uh.start.chapitre == start.chapter &&
            uh.start.numero == start.verse && 
            uh.start.decalage == start.shift;
    });
}

const findUserNote = (userNotes, start) => {
    return _.find(userNotes, (un) => {  
     
        return un.version == start.version && 
            un.livre == start.book && 
            un.chapitre == start.chapter &&
            un.verset == start.verse && 
            un.decalage == start.shift;
    });
}

const createNewUserNote = (userId, start) => {

    return {
        uuid: uuid(),
        userId: userId,
        version: start.version,
        livre: start.book,
        chapitre: start.chapter,
        verset: start.verse,
        decalage: start.shift,
        contenu: ''
    }
}

const saveUserHighlight = _.debounce( (user, userHighlight) => {
    fetch(
        `/api/v2/user_highlight/${userHighlight.uuid}`,
        {
            method: 'POST',
            body: JSON.stringify(userHighlight),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
            }
        }
    );
}, 250);

const saveUserTheme = _.debounce( (user, userTheme) => {
    fetch(
        `/api/v2/user_theme/${userTheme.uuid}`,
        {
            method: 'POST',
            body: JSON.stringify(userTheme),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
            }
        }
    );
}, 250);

const saveUserNote = _.debounce ((user, userNote) => {
    fetch(
        `/api/v2/user_note/${userNote.uuid}`,
        {
            method: 'POST',
            body: JSON.stringify(userNote),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
            }
        }
    );
}, 250 );

export {
    createNewUserHighlight,
    createNewUserNote,
    createNewUserTheme,
    dasharray,
    fillByLine,
    findHighlight,
    findUserNote,
    findUserTheme,
    saveUserHighlight,
    saveUserNote,
    saveUserTheme
};