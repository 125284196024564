import axios from 'axios';

export const ApiGetBookmarks = async user => {

	const response = await axios.get(`${ process.env.REACT_APP_API_BIBLE }/api/user/me/bookmark`, {
		headers: {
            Authorization: 'Bearer ' + user.token,
            'Content-Type': 'application/json',
            crossdomain: true 
		},
    })
    .then( (success) => { return success.data; })
    .catch( (error) => { 
        throw new Error(error.response.data.message);
    });
    
    return await response;

};

export default ApiGetBookmarks;